import BookReview from "../../components/BookReview";

const Shining = () => {
    return (
        <BookReview
            text={["An extremely strange book. The writing was very jagged. The narrator would say a thing and then affirm it with a “yes.” I’m not sure if this is how Fosse normally writes or if he was trying to get across some sort of indecisiveness in this particular book.",
                "The story was about a guy who drove along a forest road, going alternately left and right until his car became stuck. Then, he decides that instead of staying on the road, he should look in the dark forest for help. He finds a white presence (shining) which walks beside him and seems to guide him for a while. Then he finds his parents in bare feet (he realizes he is himself without shoes) who admonish him for various things. The mom berates the father for never saying anything except yes and no.",
                "He loses his family briefly but in the end they meet each other and merge under the shining presence. I think it was a metaphor for life although I’m not sure: something like life is like a deep dark forest that you navigate without any real idea of where you’re going with the help of your parents (who symbolize friends and family?) until one day you all end up being encased in the shimmer (death?).",
                "There was also a smooth rock the narrator sat on a number of times under a canopy of branches. Perhaps this was quite literally his rock in the storm, a place/event in his life that helped ground him in the darkness."
            ]}
            title={"A Shining"}
            quotes={["But was this a vision and not reality. Maybe I could carefully try to touch it to find out. But you can’t just touch a whiteness like that. Because if you did you’d probably get it dirty. And imagine getting something so white dirty. No, how could I even think of doing something like that. Or maybe I hadn’t thought about doing it, the thought had just sort of popped up, but only as a thought, not as something I was actually thinking about doing. No, of course not.",
                "Because evil angels are angels of light too, maybe all angels radiate white, both the good ones and the evil ones. Or maybe all angels are both good and evil, it certainly might be like that too.",
                "Love, now what do I mean by a word like that, because if there’s any word in the world that doesn’t mean anything it’s that one.",
                "But a silence can’t speak, can it. Yes, silence can speak in its way, and the voice you hear when it does, yes, whose voice it is. But it’s just a voice. There’s nothing else you can say about the voice. It’s just there. It’s there, no doubt about it, even though it isn’t saying anything.",
                "But is it possible to only experience something and not have it be happening. Everything you experience, yes, it’s real in a way, yes, and you probably understand it too, in a way."
            ]}
            date={"December 25, 2023"}
            rating={"3.5"}
            author="Jon Fosse"
            reviewed={true}
        />
    )
}

export default Shining;

import { ProjectProps } from "../../interfaces/projectProps";
import ProjectBlog from "../../components/ProjectBlog";

// images
import Game from "../../assets/Dewordle/dewordle.png";

const Deworlde = ({ setShowBar, showBar }: ProjectProps) => {

    return (
        <ProjectBlog
            showBar={showBar}
            setShowBar={setShowBar}
            text={["Dewordle is a game I made for my school. It's like Wordle, but\
            with a more interesting set of words. It was build with React JS and Typescript."]}
            title={"Dewordle"}
            github_link={"https://github.com/aldew5/Dewordle"}
            website_link={""}
            image1={Game}
            image2={""}
            width={"300px"}
        />
    )
}

export default Deworlde;
import BookReview from "../../components/BookReview";

const Orange = () => {
    return (
        <BookReview
            text={[]}
            title={"A Clockwork Orange"}
            quotes={["He looked a malenky bit poogly when he viddied the four of us like that.",
                "Think thou not on them. There’ll be life like down here most likely, with some getting knifed and others doing the knifing.",
                "An unmuddied lake sir. Clear as an azure sky of deepest summer.",
                "But it was not really sleep, it was like passing out into another better world.",
                "Does God want goodness or the choice of goodness? Is a man who chooses the bad perhaps in some way better than a man who has the good imposed on him?",
                "The heresy of an age of reason. I see what is right and approve, but do what is wrong.",

                "A man who ceases to choose ceases to be a man.",
                "I am everyone’s friend, except to my enemies."]}
            date={"May 7, 2023"}
            rating={"4"}
            author="Anthony Burgess"
            reviewed={false}
        />
    )
}

export default Orange;

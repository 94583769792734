import { ProjectProps } from "../../interfaces/projectProps";
import ProjectBlog from "../../components/ProjectBlog";

// images
import Game from "../../assets/FallingBlocks/Game.png";

const FallingBlocks = ({ setShowBar, showBar }: ProjectProps) => {

    return (
        <ProjectBlog
            showBar={showBar}
            setShowBar={setShowBar}
            text={["Falling Blocks is a game I made with Python3 and Pygame. The objective is (obviously) to avoid the stuff falling from the sky.\
                There are also powerups, guns and other things to help you along the way. It was one of the first games I made (which is why I included it here)\
                and I think it's a pretty entertaining as far as I-could-make-this-in-an-hour type games go."]}
            title={"Falling Blocks"}
            github_link={"https://github.com/aldew5/Falling-Blocks"}
            website_link={""}
            image1={Game}
            image2={""}
            width={"300px"}
        />
    )
}

export default FallingBlocks;
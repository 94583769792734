import { ProjectProps } from "../../interfaces/projectProps";
import ProjectBlog from "../../components/ProjectBlog";

// images
import Landing from "../../assets/kaio_girl.png";

const Kaio = ({ setShowBar, showBar }: ProjectProps) => {

    return (
        <ProjectBlog
            showBar={showBar}
            setShowBar={setShowBar}
            text={["I built a website for Kaio:",
                "Kaio makes NFTs generated to fit their pirate-anime theme. Our core belief is to provide value to our holders by hosting exclusive events and creating other compelling perks. Our mission is to build a strong community that intertwines with the metaverse and the real world."]}
            title={"Kaio"}
            github_link={"https://github.com/aldew5"}
            website_link=""
            image1={Landing}
            image2={""}
            width={"400px"}
        />
    )
}

export default Kaio;
import BookReview from "../../components/BookReview";

const Feynman = () => {

    return (
        <BookReview
            text={["Feynman wrote exactly like I would have imagined he spoke: with a chaotic, witty passion and a carefree attitude towards life and all that it encompasses. He seemed to bounce from one interesting job to the next, attempting to seduce various women along the way. He moved to a desert to help build the first atomic bomb but made sure to choose a room facing the women's living quarters. He was a professor of physics and simultaneously an avid supporter of the local strip club. Feynman was special because he refused to adhere to stereotypes or behave as he may have been expected to. He did as he pleased and let his passion for learning carry him through life.",

                "The novel was primarily focused on his (mostly) successful exploits, but I don't think Feynman consciously avoided discussing his failures. I suspect he probably laughed when he failed, just as he laughed when he succeeded. Viewed from this perspective, it's hard to even classify experiences with this binary scheme. They are simply experiences, and perhaps the successful ones naturally made for a better story.",

                "At first, I found the novel a little bit discontinuous. The events were roughly chronological, but every once and a while, Feynman flipped back to some previous event in his life. After some time, I started to accept and enjoy the book's haphazard arrangement. While reading, I often found myself encouraged to live life a little bit more like Feynman did: think a little bit more about big things and a little less about small things. I think we all sometimes picture the exceptional person sitting at their desk in a constant productive state, always working to be &quot;better than us&quot; (whatever that actually means). But this is simply not how people exist! Even the most accomplished people have lives and make jokes and occasionally do ridiculous things. Everyone needs to be reminded of that sometimes, and that is what this book does."]}
            title={"Surely You're Joking Mr. Feynman!"}
            quotes={["You have no responsibility to live up to what other people think you ought to accomplish. I have no responsibility to be like they expect me to be. It's their mistake, not my failing.",
                "All the time you're saying to yourself, 'I could do that, but I won't,' —- which is just another way of saying that you can't.",
            ]}
            date={"March 23, 2023"}
            rating={"5"}
            author="Richard Feynman"
            reviewed={true}
        />
    )
}

export default Feynman;

import Title from "../../components/BlogsAndNotes/Title";
import styles from "../../styles/BlogsAndNotes.module.css";


const Favourites = () => {

    return (
        <div>
            <div style={{ color: "black", marginTop: "2rem", textAlign: "left" }}>
                <div className={styles.font}>
                    These are my current favourite books. I'm sure that this list will change quite
                    regularly but I highly doubt that <i>East of Eden</i> will ever be removed from it.
                    Click the titles to see my reviews.
                </div>
            </div>
            <div className={styles.blogs}>
                <Title
                    date={"January 19, 2023"}
                    text={"1. East of Eden by John Steinbeck"}
                    title={"eden"}
                    small={false}
                    pdf={null}
                />
            </div>
            <div className={styles.blogs}>
                <Title
                    date={"September 10, 2023"}
                    text={"2. Infinite Jest by David Foster Wallace"}
                    title={"infinite"}
                    small={false}
                    pdf={null}
                />
            </div>
            <div className={styles.blogs}>
                <Title
                    date={"June 25, 2023"}
                    text={"3. War and Peace by Leo Tolstoy"}
                    title={"warpeace"}
                    small={false}
                    pdf={null}
                />
            </div>
            <div className={styles.blogs}>
                <Title
                    date={"May 21, 2023"}
                    text={"4. A Hunger Artist by Franz Kafka"}
                    title={"hunger"}
                    small={false}
                    pdf={null}
                />
            </div>
            <div style={{ marginBottom: "4rem" }} className={styles.blogs}>
                <Title
                    date={"September 21, 2023"}
                    text={"5. Mrs. Dalloway by Virginia Woolf"}
                    title={"dalloway"}
                    small={false}
                    pdf={null}
                />
            </div>
        </div>
    )
}

export default Favourites;
import BookReview from "../../components/BookReview";

const Algebra = () => {
    return (
        <BookReview
            text={["I read this book as part of my undergraduate degree in math (specifically, it was required reading for Algebra I and II -- MAT240 and MAT247, respectively -- at UofT). I think the 'done right' part emphasizes the obvious absence of the determinant in all but one chapter of the book (the last chapter). The determinant-free proofs were nice, but from my naive perspective, it seems perhaps a little excessive to leave the introduction of such an important concept to the last chapter. Axler demonstrated that one can go far without the determinant, but the concept frequently appears in many other texts and contexts. The purported pedagogical benefit of the layout prevents the reader from properly understanding more traditional texts on linear algebra as quickly.",
                "The book is very thorough and explains everything very well. It builds from the basics of linear algebra to abstract vector spaces. Then Axler moves on to inner product spaces, discussing orthonormality, operators and, of course, the spectral theorem for normal operators. Notably, Axler does not include the spectral resolution formulation of the theorem, which makes its links to functional calculus more clear. He also does not go into much discussion of the spectral properties of self-adjoint or orthogonal operators. A very nice proof of singular value decomposition is included, and this important result is nicely explained from a theoretical standpoint. There is no focus on application to algorithms or other disciplines of applied math; the book is purely theoretical.",
                "I would definitely recommend Axler to those looking for a rigorous introduction to abstract linear algebra. I would, however, suggest that they take a peek at Chapter 10 (determinants) in between Chapters 5 and 6."]}
            title={"Linear Algebra Done Right"}
            quotes={[]}
            date={"April 6, 2023"}
            rating={"4.5"}
            author="Sheldon Axler"
            reviewed={true}
        />
    )
}

export default Algebra;

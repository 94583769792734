import BookReview from "../../components/BookReview";

const Slaughter = () => {
    return (
        <BookReview
            text={[""]}
            title={"Slaughterhouse-Five"}
            quotes={["What he meant, of course, was that there would always be wars, that they were as easy to stop as glaciers.",
                "The second hand on my watch would twitch once, and a year would pass, and then it would twitch again.",
                "If everybody would leave him alone for just a little while, he thought, he wouldn’t cause anybody any more trouble. He would turn to steam and float up among the treetops.",
                "Later on in his life, the Tralfamadorians would advise Billy to concentrate on the happy moments of his life and ignore the unhappy ones--to stare only at pretty things as eternity failed to go by.",
                "How nice--to feel nothing, and still get full credit for being alive.",
                "That’s the attractive thing about war. Absolutely everybody gets a little bit of something.",
                "Before you kill somebody, make absolutely sure he isn’t well connected.",
                "Well here you are, Mr Pilgrim, trapped in the amber of this moment. There is no why."]}
            date={"May 13, 2023"}
            rating={"4"}
            author="Kurt Vonnegut"
            reviewed={false}
        />
    )
}

export default Slaughter;

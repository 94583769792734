import SideBar from "./SideBar";
import styles from "../styles/Template.module.css";
import TopBar from "./TopBar";

import {
    Dispatch,
    SetStateAction,
} from "react";

interface ProjectBlogProps {
    showBar: boolean;
    setShowBar: Dispatch<SetStateAction<boolean>>;
    text: Array<string>;
    title: string;
    github_link: string;
    website_link: string;
    image1: any;
    image2: any;
    width: string;
}


const ProjectBlog = ({ setShowBar, showBar, text, title, github_link, website_link,
    image1, image2, width }: ProjectBlogProps) => {

    return (
        <div>
            <div>
                <div style={{ display: "flex" }}>
                    <TopBar
                        showBar={showBar}
                        setShowBar={setShowBar}
                    />
                </div>
                {(showBar) ?
                    <div className={styles.bar}>
                        <SideBar
                            showBar={showBar}
                            setShowBar={setShowBar}
                        />
                    </div>
                    : <div></div>
                }
            </div>
            <div className={(showBar) ? styles.main_bar : styles.main_normal}>

                <div className={styles.para}>
                    <div>
                        <p style={{ fontSize: "40px" }}>
                            {title}
                        </p>
                    </div>

                    <div>
                        {(image2 !== "") ?
                            <div>
                                <div style={{ display: 'flex', float: 'left' }}>
                                    <img src={image1} width={width} alt="not found" />
                                </div>
                                <div >
                                    <img src={image2} width={width} alt="not found" />
                                </div>
                            </div>
                            :
                            <div>
                                <img src={image1} width={width} alt="not found" />
                            </div>

                        }
                    </div>
                    <div style={{ marginTop: "4rem", fontSize: "20px" }}>
                        {text.map(d => {
                            return (
                                <p>{d}</p>
                            )
                        })}
                        {(website_link === "") ?
                            <p className={styles.link} onClick={() => {
                                window.location.href = github_link;
                            }}>
                                Github
                            </p>
                            :
                            <div>
                                <p className={styles.link} onClick={() => {
                                    window.location.href = github_link;
                                }}>
                                    Github
                                </p>
                                <p className={styles.website} onClick={() => {
                                    window.location.href = website_link;
                                }}>
                                    Website
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectBlog;
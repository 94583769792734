import BookReview from "../../components/BookReview";

const Hitchhiker = () => {

    return (
        <BookReview
            text={[]}
            title={"The Hitchhiker's Guide to the Galaxy"}
            quotes={["Arthur prodded the mattress nervously and then sat on it himself: in fact he had very little to be nervous about, because all mattresses grown in the swamps of Sqornshellous Zeta are very thoroughly killed and dried before being put to service. Very few had ever come back to life again.",
                "During a recitation by their Poet Master Grunthos the Flatulent of his poem ‘Ode To A Small Lump of Green Putty I Found In My Armpit one Midsummer Morning’ four of his audience died of internal hemorrhaging, and the President of the Mid-Galactic Art Nobbling Council survived by gnawing one of his own legs off.",
                "The principle of generating small amounts of finite improbability by simply hooking the logic circuit of a Bambleweeny 57 Sub-Meson Brain to an atomic vector plotter suspended in a strong Brownian Motion producer (say a nice hot cup of tea) were of course well understood -- and such generators were often used to break the ice at parties by making all the molecules in the hostess’s undergarments leap simultaneously one foot to left.",
                "Probability factor of one to one … we have normality .. Anything you still can’t cope with is therefore your own problem.",
                "He is the best bang since the big one.",
                "If they don’t stop exercising their lips, their brains start working.",
                "They show two to the power of infinity minus one to one against (an irrational number that only has a conventional meaning in improbability physics).",
                "The Answer to the Great Question of Life, the Universe and Everything is forty-two, said Deep Thought, with infinite majesty and calm.",
                "The Great Hyperlobic, Omni-Cognate Neutron Wrangler said Deep Thought could talk all four legs off an Arcturian Megadonkey–but only I could persuade it to go for a walk afterwards.",
                "Why are people born? Why do they die? Why do they want to sped so much of the intervening time wearing digital watches?",
            ]}
            date={"May 16, 2023"}
            rating={"5"}
            author="Douglas Adams"
            reviewed={false}
        />

    )
}

export default Hitchhiker;

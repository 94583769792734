import { Typography } from "@mui/material";
import styles from "../../styles/Entries.module.css";
import { useNavigate } from "react-router-dom";

const Hitch = () => {

    const navigate = useNavigate();

    return (
        <div>
            <div style={{ width: "60%", margin: "auto", marginTop: "5%" }}>
                <div className={styles.title}>
                    The Hitchhiker’s Guide to the Galaxy is a must-read for all sci-fi fans
                    <hr />
                </div>
                <div className={styles.container}>
                    <div className={styles.date}>
                        September 9, 2023
                    </div>
                    <div className={styles.link} onClick={() => { navigate("/") }}>
                        See all posts
                    </div>
                </div>
                <div className={styles.body}>
                    <Typography variant="body1">
                        <p>The <i>Hitchhiker’s Guide to the Galaxy</i> is a veritable masterpiece of science fiction. It’s ostensibly random but somehow still coherent, and the events are hilarious in a delightfully clever way. It’s what you might imagine to be the result of putting a witty, extremely creative scientist in a room and telling them to write whatever comes to mind. It’s impossible to do the book any semblance of justice in a short plot summary, so if you find any of the events described below to be even somewhat funny or intriguing, go read it; you won’t be disappointed.</p>
                        <p>The novel begins on Earth, where the story’s main character, Arthur Dent, is fighting to keep his house from being bulldozed to make room for a highway. The demolition crew slated to carry out this task is led by a shabby man who, from 9 to 5, seems to dutifully restrict his attention solely to matters of bulldozing. While Arthur may understand this, he is not sufficiently empathetic with this man’s devotion to sanctioned destruction to step aside and watch his home be flattened. Hence, they arrive at an impasse. </p>
                        <p>If you’re thinking that the aforementioned dilemma seems overly mundane for a novel about the galaxy, you would be right. The much bigger problem is that the Earth itself is set to be bulldozed on the same day for the galactically analogous reason: to make room for a highway. Yes, the irony is overly contrived, but that’s exactly the point. This is just the tip of the that’s-so-strange-and-creative-it’s-funny iceberg. After Arthur escapes Earth right before it’s destroyed, the problems he faces become increasingly inventive in a crescendo that culminates in his near death at the hands of the mice (more on this later). </p>
                        <p>At present, Arthur has just seen his planet incinerated and is now aboard a spaceship called the <i>Heart of Gold</i> with a group of whimsically-named aliens and their hopelessly depressed robot, Marvin. They are traveling to a planet called Magrathea, whose very existence has become a subject of galactic contention. Traveling the light-years separating Magrathea from Earth would be an insoluble problem for most spaceships, but luckily the Heart of Gold is no ordinary spacecraft. Its engine operates based on the laws of <i>improbability physics</i>.</p>
                        <p>Explaining this complicated branch of (mostly fabricated) physics in a few lines would be infeasible, so we’ll just concern ourselves with its application to the spaceship. The Heart of Gold is simultaneously everywhere at once, much like an electron (or an elephant). By making the so-called improbability factor sufficiently high, it becomes likely for the ship to be, say, across the galaxy at Magrathea. An engine that allows you to artificially shift the probabilities of a ship being in certain positions sounds virtually impossible because it is! Its virtual impossibility makes it <i>finitely improbable</i>, and working out this finite improbability is what allowed for its construction.</p>
                        <p>When Arthur et al. finally reach Magrathea, they find it not only inhabited but quite busy. It turns out that Magrathea’s economy is based around the luxury planet-building business and that they were recently assigned a new planet to build: Earth Mark 2. </p>
                        <p>Who, you might ask, would be willing to spend the money to create another version of a planet that was only recently determined to be less valuable than a highway? Well, the mice, of course.</p>
                        <p>It’s common knowledge in most regions of the galaxy that mice are the most intelligent species on Earth by a considerable margin. Arthur learns that they’ve been deceiving humans for thousands of years with the whole inane squeaking charade so that they could monitor their experiment (i.e. Earth). Earth’s premature destruction had forced the mice to restart their 10 million-year computation by building another one.</p>
                        <p>When the mice hear of Arthur’s arrival on Magrathea, they invite him to a private meeting where all of this is explained. Arthur is given the opportunity to sell his brain, which the mice believe may hold the answer (he is not offered a replacement brain). In the interest of keeping his head firmly attached to his neck, Arthur and the rest of the crew make a rush for the Heart of Gold. All members, including the suicidal robot Marvin, make it off Magrathea safely.</p>
                        <p>And then they go for lunch.</p>
                        <p>I don’t think it’s at all an overstatement to say that The Hitchhiker’s Guide to the Galaxy sparks imagination. It targets that voice in your head that occasionally encourages you to put popcorn kernels in pancakes so that they might flip themselves. If you’ve never considered automatically flipping pancakes, perhaps a lesson in improbability physics would do you good.</p>
                   </Typography>
                </div>
                <div className={styles.body}>
                    This article was published in University of Toronto's <i>The Varsity</i>. 
                    The editors took the liberty of making changes without my consent, so this version may be slightly different from the one above. You can find it&nbsp;
                    <a 
                        href="https://thevarsity.ca/2023/09/09/the-hitchhikers-guide-to-the-galaxy-a-review/">here</a>.
                    <p><br /></p>
                </div>
            </div>
        </div>
    )
}

export default Hitch;
import { ProjectProps } from "../../interfaces/projectProps";
import ProjectBlog from "../../components/ProjectBlog";

// images
import Hub from "../../assets/AshburyBook/Hub.png";
import Profile from "../../assets/AshburyBook/Profile.png"

const AshburyBook = ({ setShowBar, showBar }: ProjectProps) => {

    return (
        <ProjectBlog
            showBar={showBar}
            setShowBar={setShowBar}
            text={["I built the Ashbury Book with the MERN stack to manage the games and statistics of my dorm's poker semi-legal poker league. It does user login with JWTs, features a leaderboard, a schedule of upcoming games, debt tracking and more."]}
            title={"Ashbury Book"}
            github_link={"https://github.com/Matthews-House-Poker"}
            website_link={"https://ashburybook.netlify.app/"}
            image1={Hub}
            image2={Profile}
            width={"100%"}
        />
    )
}

export default AshburyBook;
import ProjectBlog from "../../components/ProjectBlog";
import { ProjectProps } from "../../interfaces/projectProps";

// images
import Instructions from "../../assets/TvLExperiment/Instructions.png";
import Experiment from "../../assets/TvLExperiment/Experiment.png";

const TvLExperiment = ({ setShowBar, showBar }: ProjectProps) => {
    return (

        <ProjectBlog
            showBar={showBar}
            setShowBar={setShowBar}
            text={['This application was built as part of my RSI 2021 project on the effects of priming in visual search. Users search for a T among Ls and click the left or right arrow key to specify its orientation. This version of the experiment uses "sharp" and "dotted" Ts. We use it for shape priming. Other versions of the experiment use color and size as priming features. Our results were described in my paper "Effects of Priming on the Deployment of Attention in Visual Search". It was built with React JS and Firebase.']}
            title={"TvLExperiment"}
            github_link={"https://github.com/aldew5/TvL-Experiment-Public"}
            website_link={"https://tvl-experiment.web.app/"}
            image1={Instructions}
            image2={Experiment}
            width={"300px"}
        />
    )
}

export default TvLExperiment;
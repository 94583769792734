import SideBar from "../components/SideBar";
import styles from "../styles/Template.module.css";
import ProfilePicture from "../assets/profile.png";
import TopBar from "../components/TopBar";
import {
    Dispatch,
    SetStateAction,
} from "react";

interface ContactProps {
    showBar: boolean;
    setShowBar: Dispatch<SetStateAction<boolean>>;
}

const Contact = ({ showBar, setShowBar }: ContactProps) => {
    return (
        <div>
            <div>
                <div style={{ display: "flex" }}>
                    <TopBar
                        showBar={showBar}
                        setShowBar={setShowBar}
                    />
                </div>
                {(showBar) ?
                    <div className={styles.bar}>
                        <SideBar
                            showBar={showBar}
                            setShowBar={setShowBar}
                        />
                    </div>
                    : <div></div>
                }
            </div>
            <div style={{ width: "100%" }}>
                <div className={styles.para2}>
                    <div style={{ marginBottom: "2rem", fontSize: "40px" }}>
                        Contact
                    </div>
                    <div style={{ marginBottom: "2rem", fontSize: "20px", width: "90%" }}>
                        I love working on cool technology and I'm always looking for new projects to be a part of.
                        Please contact me if you have one. I'm also open to questions or even just conversations
                        about interesting things. Send me a email at <b>adewulf [ at ] cs [ dot ] toronto [ dot ] edu</b>.
                    </div>
                </div>
                <div style={{ margin: "auto", width: "300px" }}>
                    <img src={ProfilePicture} className={styles.profile} alt="profile picture not found" />
                </div>
            </div>
        </div>
    )
}

export default Contact;
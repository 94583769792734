import { Typography } from "@mui/material";
import SideBar from "../components/SideBar";
import Title from "../components/BlogsAndNotes/Title";
import styles from "../styles/BlogsAndNotes.module.css";
import TopBar from "../components/TopBar";
import Priming from "../assets/priming.pdf";
import Pspace from "../assets/CSC2429.pdf";
import {
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";

interface PublicationsProps {
    showBar: boolean;
    setShowBar: Dispatch<SetStateAction<boolean>>;
}

const Publications = ({ showBar, setShowBar }: PublicationsProps) => {

    return (
        <div>
            <div>
                <TopBar
                    showBar={showBar}
                    setShowBar={setShowBar}
                />
            </div>
            {(showBar) ?
                <div className={styles.bar}>
                    <SideBar
                        showBar={showBar}
                        setShowBar={setShowBar}
                    />
                </div>
                : <span></span>
            }
            <div className={(showBar) ? styles.main_bar2 : styles.main_normal2}>
                <div className={styles.title}>
                    <div>
                        <Typography variant="h4"
                            style={{
                                marginBottom: "2rem",
                            }}>
                            Publications
                        </Typography>
                        <Typography variant="body1"
                            style={{
                                marginBottom: "1rem",
                            }}>
                            "All we can know is that we know nothing. And that is the height of human wisdom."
                            - Leo Tolstoy
                        </Typography>
                    </div>
                    <div >
                        <hr />
                    </div>
                    <div className={styles.blogs}>
                        <Title
                            date={"February 27, 2024"}
                            text={"A Downward Self-Reducible, Random Self-Reducible PSPACE-complete Problem"}
                            title={""}
                            small={false}
                            pdf={Pspace}
                        />
                    </div>
                    <div className={styles.blogs}>
                        <Title
                            date={"September 9, 2023"}
                            text={"The Hitchhiker’s Guide to the Galaxy Review"}
                            title={"hitch"}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div className={styles.blogs}>
                        <Title
                            date={"May 24, 2022"}
                            text={"Priming in Inefficient Visual Search: Real, but Transient"}
                            title={""}
                            small={false}
                            pdf={Priming}
                        />
                    </div>
                    <div className={styles.blogs}>
                        <Title
                            date={"September 26, 2022"}
                            text={"A Recap of Apple's Most Recent Keynote Event"}
                            title={"apple"}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div className={styles.blogs} style={{marginBottom: '4rem'}}>
                        <Title
                            date={"October 6, 2022"}
                            text={"Book Review: How Not to be Wrong"}
                            title={"howWrong"}
                            small={false}
                            pdf={null}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Publications;
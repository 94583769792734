import { Typography } from "@mui/material";
import SideBar from "../components/SideBar";
import Menu from "../components/BlogsAndNotes/Menu";
import Title from "../components/BlogsAndNotes/Title";
import styles from "../styles/BlogsAndNotes.module.css";
import TopBar from "../components/TopBar";
import { useState } from "react";
import MAT157 from "../assets/mat157.pdf";
import MAT240 from "../assets/mat240.pdf";
import Topology from "../assets/Topological_Potpourri.pdf";
import Algorithms from '../assets/CLRS_Introduction_to_Algorithms.pdf';
import Manifold from "../assets/Spivak_Calculus_on_Manifolds.pdf";
import Time from "../assets/Time_Hierarchy.pdf";
import Bounds from "../assets/bounds.pdf";
import Taint from "../assets/Taint.pdf";
import {
    Dispatch,
    SetStateAction,
} from "react";

interface NotesProps {
    showBar: boolean;
    setShowBar: Dispatch<SetStateAction<boolean>>;
}

const Notes = ({ showBar, setShowBar }: NotesProps) => {

    const [science, setScience] = useState<boolean>(true);
    const [scifi, setScifi] = useState<boolean>(true);
    const [general, setGeneral] = useState<boolean>(true);
    const [math, setMath] = useState<boolean>(true);

    return (
        <div>
            <div>
                <TopBar
                    showBar={showBar}
                    setShowBar={setShowBar}
                />
            </div>
            {(showBar) ?
                <div className={styles.bar} style={{height: "121%"}}>
                    <SideBar
                        showBar={showBar}
                        setShowBar={setShowBar}
                    />
                </div>
                : <span></span>
            }
            <div className={(showBar) ? styles.main_bar2 : styles.main_normal2}>
                <div className={styles.bar}>
                </div>
                <div className={styles.title}>
                    <div>
                        <Typography variant="h4"
                            style={{
                                marginBottom: "1rem",
                            }}>
                            Notes
                        </Typography>
                        <Typography variant="body1"
                            style={{
                                marginBottom: "1rem",
                            }}>
                            "The man who is ready to taste every branch of learning, is glad to learn
                            and is never satisfied &mdash; he's the man who deserves to be called a philosopher."
                            - Plato
                        </Typography>
                    </div>
                    <div >
                        <Menu
                            setScience={setScience}
                            setScifi={setScifi}
                            setGeneral={setGeneral}
                            setMath={setMath}
                        />
                    </div>
                    <div className={styles.blogs}>
                        {(science || general) ?
                            <Title
                                date={"June 3, 2024"}
                                text={"Taint Tagging Systems"}
                                title={""}
                                small={false}
                                pdf={Taint}
                            />
                            : <></>
                        }
                    </div>
                    <div className={styles.blogs}>
                        {(science || general) ?
                            <Title
                                date={"April 26, 2024"}
                                text={"a.a.i Lower Bounds vs. Witness Lower Bounds"}
                                title={""}
                                small={false}
                                pdf={Bounds}
                            />
                            : <></>
                        }
                    </div>
                    <div className={styles.blogs}>
                        {(science || general) ?
                            <Title
                                date={"April 26, 2024"}
                                text={"The Nondeterministic Time Hierarchy"}
                                title={""}
                                small={false}
                                pdf={Time}
                            />
                            : <></>
                        }
                    </div>
                    <div className={styles.blogs}>
                        {(science || general) ?
                            <Title
                                date={"July 27, 2023"}
                                text={"Algorithms and Data Structures (CLRS Solutions)"}
                                title={""}
                                small={false}
                                pdf={Algorithms}
                            />
                            : <></>
                        }
                    </div>
                    <div className={styles.blogs}>
                        {(math || general) ?
                            <Title
                                date={"July 19, 2023"}
                                text={"Calculus on Manifolds (Spivak Solutions)"}
                                title={""}
                                small={false}
                                pdf={Manifold}
                            />
                            : <></>
                        }
                    </div>
                    <div className={styles.blogs}>
                        {(math || general) ?
                            <Title
                                date={"July 16, 2023"}
                                text={"A Topological Potpourri"}
                                title={""}
                                small={false}
                                pdf={Topology}
                            />
                            : <></>
                        }
                    </div>
                    <div className={styles.blogs}>
                        {(math || general) ?
                            <Title
                                date={"December 14, 2022"}
                                text={"Analysis I (MAT157) Notes"}
                                title={""}
                                small={false}
                                pdf={MAT157}
                            />
                            : <></>
                        }
                    </div>
                    {/*<div className={styles.blogs}>
                    {(math || science || general) ?
                        <Title
                            date={"April 24, 2022"}
                            text={"Quantum State Spaces"}
                            title={"StateSpaces"}
                        />
                        : <></>
                    }
                </div>*/}
                    <div className={styles.blogs} style={{marginBottom: "2rem"}}>
                        {(math || general) ?
                            <Title
                                date={"December 13, 2022"}
                                text={"Algebra I (MAT240) Notes"}
                                title={""}
                                small={false}
                                pdf={MAT240}
                            />
                            : <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notes;
import { ProjectProps } from "../../interfaces/projectProps";
import ProjectBlog from "../../components/ProjectBlog";

// images
import Trial from "../../assets/MammogramChecking/trial.png";

const MammogramChecking = ({ setShowBar, showBar }: ProjectProps) => {

    return (
        <ProjectBlog
            showBar={showBar}
            setShowBar={setShowBar}
            text={["This app is part of my internship at the HMS/BWH Visual Attention Lab.\
            It will be used to test radiologists' confidence in allowing AI to be integrated into\
            the mammogram screening procedure. Radiologists are shown an MLO mammogram and are asked\
             to rate its suspiciousness on a scale from 1 to 10. A rating of 10 would mean the radiologist\
              is certain there is a cancer present and the patient should be recalled. The radiologist is\
               then shown the AI's rating of the image and asked how confident they are in allowing the AI\
                to make a decision based on this particular rating."]}
            title={"Mammogram Checking"}
            github_link={"https://github.com/aldew5/Mammogram-Checking"}
            website_link={""}
            image1={Trial}
            image2={""}
            width={"400px"}
        />
    )
}

export default MammogramChecking;

import { useEffect, useState } from "react";

import Profile from "./pages/Profile";
import Landing from "./pages/Landing";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Notes from "./pages/Notes";
import Reading from "./pages/Reading";


import LifeOne from "./pages/BlogEntries/LifeOne";
import AshburyBook from "./pages/ProjectPages/AshburyBook";


import Eden from "./pages/Reviews/Eden";
import Feynman from "./pages/Reviews/Feynman";
import Hunger from "./pages/Reviews/Hunger";
import Crime from "./pages/Reviews/Crime";
import Cities from "./pages/Reviews/Cities";
import War from "./pages/Reviews/War";
import Brave from "./pages/Reviews/Brave";
import Algebra from "./pages/Reviews/Algebra";
import Sea from "./pages/Reviews/Sea";
import Delusion from "./pages/Reviews/Delusion";
import Orange from "./pages/Reviews/Orange";
import Hitchhiker from "./pages/Reviews/Hitchhiker";
import Slaughter from "./pages/Reviews/Slaughter";
import Dalloway from "./pages/Reviews/Dalloway";
import Road from "./pages/Reviews/Road";
import Resume from "./assets/resume.pdf";


import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import TvLExperiment from "./pages/ProjectPages/TvLExperiment";
import ARCVision from "./pages/ProjectPages/ARCVision";
import FallingBlocks from "./pages/ProjectPages/FallingBlocks";
import Dewordle from "./pages/ProjectPages/Dewordle";
import MammogramChecking from "./pages/ProjectPages/MammogramChecking";
import Publications from "./pages/Publications";
import ConvNet from "./pages/NoteEntries/ConvNet";
import Kaio from "./pages/ProjectPages/Kaio";
import Detection from "./pages/NoteEntries/Detection";
import Camp from "./pages/BlogEntries/Camp";
import EmptyNotebook from "./pages/BlogEntries/EmptyNotebook";
import HowWrong from "./pages/BlogEntries/HowWrong";
import Apple from "./pages/BlogEntries/Apple";
import WarPeace from "./pages/Reviews/WarPeace";
import Infinite from "./pages/Reviews/Infinite";
import Shining from "./pages/Reviews/Shining";
import Hitch from "./pages/BlogEntries/Hitch";

function App() {

  const [timeline, setTimeline] = useState<boolean>(false);
  const [list, setList] = useState<boolean>(true);
  const [favourites, setFavourites] = useState<boolean>(false);
  const [showBar, setShowBar] = useState<boolean>(false);
  /*
  const saveUser = async (ip: String, city: String) => {
    const request = await fetch(`${process.env.REACT_APP_API_URL}/saveUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      body: JSON.stringify({
        IPv4: ip,
        city: city
      }),
    });
    const response = await request.json();
  }

  const getIP = async () => {
    const response = await fetch('https://geolocation-db.com/json/');
    const data = await response.json();
    saveUser(data.IPv4, data.city);
  }

  useEffect(() => {
    getIP();
  }, []);

  */

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/profile" element={
            <Profile
              showBar={showBar}
              setShowBar={setShowBar}
            />
          }>
          </Route>
          <Route path="/about" element={
            <About
              showBar={showBar}
              setShowBar={setShowBar}
            />
          } />
          {/*<Route path="/blog" element={<Blog
            showBar={showBar}
            setShowBar={setShowBar}
          />} />*/}
          <Route path="/contact" element={<Contact
            showBar={showBar}
            setShowBar={setShowBar}
          />} />
          <Route path="/notes" element={<Notes
            showBar={showBar}
            setShowBar={setShowBar}
          />} />
          <Route path="/" element={<Publications
            showBar={showBar}
            setShowBar={setShowBar}
          />} />
          <Route path="/reading" element={<Reading
            favourites={favourites}
            list={list}
            timeline={timeline}
            setFavourites={setFavourites}
            setTimeline={setTimeline}
            setList={setList}
            showBar={showBar}
            setShowBar={setShowBar}
          />} />
          {/*Blog posts*/}
          <Route path="/howWrong" element={<HowWrong />} />
          <Route path="/apple" element={<Apple />} />
          {/*Notes*/}
          {/*Project Pages*/}
          <Route path="/ashburyBook" element={<AshburyBook
            showBar={showBar}
            setShowBar={setShowBar}
          />} />
          <Route path="/tvlExperiment" element={<TvLExperiment
            showBar={showBar}
            setShowBar={setShowBar}
          />} />
          <Route path='/arcvision' element={<ARCVision
            showBar={showBar}
            setShowBar={setShowBar}
          />} />
          <Route path="/fallingblocks" element={<FallingBlocks

            showBar={showBar}
            setShowBar={setShowBar}
          />} />
          <Route path="/dewordle" element={<Dewordle
            showBar={showBar}
            setShowBar={setShowBar}
          />} />
          <Route path="/mammogramchecking" element={<MammogramChecking
            showBar={showBar}
            setShowBar={setShowBar}
          />} />
          <Route path="/kaio" element={<Kaio
            showBar={showBar}
            setShowBar={setShowBar}
          />} />
          {/*Books*/}
          <Route path="/eden" element={<Eden />} />
          <Route path="/feynman" element={<Feynman />} />
          <Route path="dalloway" element={<Dalloway />} />
          <Route path="/hunger" element={<Hunger />} />
          <Route path="/cities" element={<Cities />} />
          <Route path="/crime" element={<Crime />} />
          <Route path="/war" element={<War />} />
          <Route path="/brave" element={<Brave />} />
          <Route path="/algebra" element={<Algebra />} />
          <Route path="/sea" element={<Sea />} />
          <Route path="/delusion" element={<Delusion />} />
          <Route path="/orange" element={<Orange />} />
          <Route path="/hitchhiker" element={<Hitchhiker />} />
          <Route path="/slaughter" element={<Slaughter />} />
          <Route path="/warpeace" element={<WarPeace />} />
          <Route path="/infinite" element={<Infinite />} />
          <Route path="/road" element={<Road />} />
          <Route path="/shining" element={<Shining />} />
          <Route path="/hitch" element={<Hitch />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

import BookReview from "../../components/BookReview";

const Eden = () => {
    return (
        <BookReview
            text={[
                "East of Eden is a book about people whose templates for the world don't align with their own realities. A man who sees light coming from a woman that only wishes to use him. A young boy who sees his partner not as she is but how he wishes to see her. He feels stretched apart between two places at once, neither of which he particularly likes. He works hard and leaves the small town in which he was born in an effort to be something. He doesn't find what he thought he would in Palo Alto and is too impatient to look anywhere else so he marches to his death. Both man and boy had ideas for how things should be and when they realized how things actually were the consequences were life-altering or life-ending.",

                "This novel gives profound insight into these deep struggles of normal life. People die, people change their minds, and sometimes people do awful things. All that we can do is make our templates less rigid so as to minimize the shock we feel when the world invariably surprises us. People whose lives revolve around a small number of things are bound to be let down and left empty.",

                "As of now, this is my favourite book (And I think it will stay that way for some time). I love Steinbeck's unpretentious writing style and the way it allows one to connect with his characters. I felt their struggles viscerally and often drew parallels with my own life. Although the actions of many of the characters are often extreme, they retain a strong resemblance to common struggles."]}
            title={"East of Eden"}
            quotes={["Sometimes a man wants to be stupid if it lets him do a thing his cleverness forbids.",

                "Feed a man, clothe him, put him in a house and he will die of despair.",

                "Open the table drawer and then make use of that turnip you call your head.",

                "It seems to me that if you or I must choose between two courses of thought or action, we should remember our dying and try so to live that our death brings no pleasure to the world.",

                "It's like a life--so quick when we don't watch it and so slow when we do.",

                "You're too young a man to be panning memories. You should be getting yourself some new ones so that the mining will be richer when you come to age.",

                "Eventless has no posts to drape duration on.",

                "Some people think it's an insult to the glory of their sickness to get well. But the time poultice is no respecter of glories. Everyone gets well if he waits around.",

                "It's hard to give people things -- I guess it's even harder to be given things.",

                "Weltschmerz. The world sadness that rises into the soul like a gas and spreads despair so that you probe for the offending event and can find none.",

                "Maybe the less you have the more you are required to boast.",

                "You can't lay in bed and run a farm.",

                "A man's mind can't stay in time the way his body does.",

                "There's a capacity for appetite that a whole heaven and earth can't satisfy.",

                "That's not how he wanted the story to go -- and he wouldn't have any other story. So he tore up the world."]}
            date={"January 19, 2023"}
            rating={"5"}
            author="John Steinbeck"
            reviewed={true}
        />
    )
}

export default Eden;

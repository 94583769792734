import { ProjectProps } from "../../interfaces/projectProps";
import ProjectBlog from "../../components/ProjectBlog";

// images
import Menu from "../../assets/ARCVision/Menu.png";
import Demo from "../../assets/ARCVision/Demo.png";

const ARCVision = ({ setShowBar, showBar }: ProjectProps) => {

    return (
        <div>
            <ProjectBlog
                showBar={showBar}
                setShowBar={setShowBar}
                text={["ARCVision is an application built with Python3 and OpenCV that allows users to program binary ArUco markers and see the results in augmented reality. It was built as an educational tool to teach young people about programming (sort of like a real-world Scratch).",
                    "Its features include variable declaration, basic loops, print statements, and operations between primatives. ARCVision was originally built as an IB HL computer science project in which it received a 7/7. I later used the same technology in another app that helped nonverbal children communicate in a more engaging way with AR. This project was supported by Ausome Ottawa."]}
                title={"ARCVision"}
                github_link={"https://github.com/aldew5/ARCVision"}
                website_link={""}
                image1={Menu}
                image2={Demo}
                width={"300px"}
            />
        </div>
    )
}

export default ARCVision;
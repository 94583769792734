import { useNavigate } from "react-router-dom";
import styles from "../../styles/Entries.module.css";
import { Typography } from "@mui/material";

const Hunger = () => {

    const navigate = useNavigate();

    return (
        <div>
            <div style={{ width: "60%", margin: "auto", marginTop: "5%" }}>
                <div className={styles.title}>
                    <i>A Hunger Aritist</i> by Franz Kafka
                    <hr />
                </div>
                <div className={styles.container}>
                    <div className={styles.date}>
                        May 21, 2023
                    </div>
                    <div className={styles.link} onClick={() => { navigate("/reading") }}>
                        See all posts
                    </div>
                    <div className={styles.body}>
                        <Typography variant="body1">
                            <p>Review Pending.
                            </p>
                            <p>Rating: 5/5</p>
                        </Typography>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Hunger;

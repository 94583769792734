import { Typography } from '@mui/material';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useNavigate } from "react-router-dom";
import Profile from "../assets/profile_bar.png";
import styles from "../styles/SideBar.module.css";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import CloseIcon from '@mui/icons-material/Close';
import 'react-pro-sidebar/dist/css/styles.css';
import {
    Dispatch,
    SetStateAction,
    useState,
} from "react";


interface SidebarProps {
    showBar: boolean;
    setShowBar: Dispatch<SetStateAction<boolean>>
}

const SideBar = ({ setShowBar, showBar }: SidebarProps) => {

    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    return (
        <ProSidebar>
            <Menu iconShape="square" style={{ height: '100%' }}>
                <MenuItem>
                    <div style={{ display: "flex", width: "50px", height: "95px"}}>
                        <img
                            src={Profile}
                            alt="profile not found"
                            className={styles.picture}
                            style={(loaded) ? {} : { display: 'none' }}
                            onLoad={() => setLoaded(true)}
                        />
                    </div>
                    <div style={{
                        display: "flex", position: "absolute", right: "0", marginRight: "10px",
                        top: "0", marginTop: "15px"
                    }}>
                        <CloseIcon
                            onClick={() => { setShowBar(!showBar); }}
                        />
                    </div>
                </MenuItem>
                {!loaded ? null : (
                    <div>
                        <MenuItem>
                            <Typography color="white" className={styles.name}>Alec </Typography>
                        </MenuItem>

                        <MenuItem onClick={() => {
                            navigate("/about");
                            setShowBar(!showBar);
                        }} className={styles.menuItem}>
                            About
                        </MenuItem>
                        <MenuItem onClick={() => {
                            navigate("/profile");
                            setShowBar(!showBar);
                        }} className={styles.menuItem}>
                            Things I've Built
                        </MenuItem>
                        {/* <MenuItem onClick={() => {
                        navigate("/blog");
                    }} className={styles.menuItem}>
                        My Blog
                    </MenuItem> */}
                        <MenuItem onClick={() => {
                            navigate("/");
                            setShowBar(!showBar);
                        }} className={styles.menuItem}>
                            Publications
                        </MenuItem>
                        <MenuItem onClick={() => {
                            navigate("/notes");
                            setShowBar(!showBar);
                        }} className={styles.menuItem}>
                            Stuff I'm Learning About
                        </MenuItem>
                        <MenuItem onClick={() => {
                            navigate("/reading");
                            setShowBar(!showBar);
                        }} className={styles.menuItem}>
                            Things I've Read
                        </MenuItem>
                        <MenuItem onClick={() => {
                            navigate("/contact");
                            setShowBar(!showBar);
                        }} className={styles.menuItem}>
                            Contact Me
                        </MenuItem>
                        <MenuItem onClick={() => {
                            window.location.href = "https://www.linkedin.com/in/alec-dewulf-45133a1a5/";
                        }} className={styles.linkedin}>
                            <LinkedInIcon />
                        </MenuItem>
                        <MenuItem onClick={() => {
                            window.location.href = "https://github.com/aldew5";
                        }} className={styles.github}>
                            <GitHubIcon />
                        </MenuItem>
                    </div>
                )}
            </Menu>
        </ProSidebar>
    )
}

export default SideBar;

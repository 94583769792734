import styles from "../styles/TopBar.module.css";
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from "@mui/material";
import {
    Dispatch,
    SetStateAction,
} from "react";

interface TopBarProps {
    showBar: boolean;
    setShowBar: Dispatch<SetStateAction<boolean>>
}


const TopBar = ({ showBar, setShowBar }: TopBarProps) => {

    const handleClick = () => {
        setShowBar(!showBar);
    }

    return (
        <div className={styles.container}>
            <div className={styles.bottom}>
                <div className={styles.text}>
                    Alec Dewulf's Blog
                </div>
                <div>
                    <IconButton onClick={handleClick}>
                        <MenuIcon
                            sx={{
                                "&:hover": {
                                    cursor: "pointer"
                                }
                            }}
                        />
                    </IconButton>
                </div>
            </div>
        </div>
    )

}

export default TopBar;
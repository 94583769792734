import { Typography } from "@mui/material";
import SideBar from "../components/SideBar";
import styles from "../styles/BlogsAndNotes.module.css";
import BookMenu from "../components/BlogsAndNotes/BookMenu";
import Favourites from "./BookPages/Favourites";
import List from "./BookPages/List";
import Timeline from "./BookPages/Timeline";
import { Dispatch, SetStateAction } from "react";
import TopBar from "../components/TopBar";
import { useState } from "react";


interface ReadingProps {
    favourites: boolean;
    timeline: boolean;
    list: boolean;
    setTimeline: Dispatch<SetStateAction<boolean>>;
    setList: Dispatch<SetStateAction<boolean>>;
    setFavourites: Dispatch<SetStateAction<boolean>>;
    showBar: boolean;
    setShowBar: Dispatch<SetStateAction<boolean>>;
}


const Reading = ({ favourites, timeline, list, setTimeline, setList, setFavourites,
    showBar, setShowBar }: ReadingProps) => {

    const CURRENT = ["The Diary of a Young Girl", "Anne Frank"];

    return (
        <div>
            <div >
                <TopBar
                    showBar={showBar}
                    setShowBar={setShowBar}
                />
            </div>
            {(showBar) ?
                <div className={styles.bar}>
                    <SideBar
                        showBar={showBar}
                        setShowBar={setShowBar}
                    />
                </div>
                : <span></span>
            }
            <div className={(showBar) ? styles.main_bar2 : styles.main_normal2}>
                <div className={styles.title}>
                    <div>
                        <Typography variant="h4" color="black"
                            style={{
                                marginBottom: "2rem",
                            }}>
                            Bookshelf
                        </Typography>
                        <Typography variant="body1" color="black"
                            style={{
                                marginBottom: "1rem",
                            }}>
                            "That’s not how he wanted the story to go &mdash; and he wouldn’t
                            have any other story. So he tore up the world." - John Steinbeck
                        </Typography>
                        <div >
                            <BookMenu
                                setList={setList}
                                setFavourites={setFavourites}
                                setTimeline={setTimeline}
                            />
                        </div>
                    </div>
                    <div>
                        {(favourites) ? <Favourites /> :
                            <div>{(list) ? <List current={CURRENT} /> : <Timeline current={CURRENT} />}</div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reading;
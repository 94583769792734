import BookReview from "../../components/BookReview";

const WarPeace = () => {
    return (
        <BookReview
            text={["A veritable masterpiece. I can't imagine a more real depiction of the zeitgeist of the Russian aristocracy during the Napoleonic wars. The characters are so intricate and complex that it's easy to forget they aren't real (most of the time). And even though there are historical figures in the novel—Kutuzov, Napolean and the like—they are depicted fictionally. By this, I mean Tolstoy seems to intermingle real events with fictional ones to strengthen his portrayal of an historical figure.",
            "The main reason that this novel is one of my favourites is because of Tostoy's view on wars (or historical events more generally) and the 'great' men (people) that ostensibly drive them. His view is simple: there are no great men. Napoleon was allowed to exist because the conditions in Paris made it possible. He rose to power because his goals aligned with those of the French people. Had Napolean been even sicker during the battle of Borodino and consequently had even fewer of his commands executed, the war would have proceeded as it did before because there is no other way it could have gone. This is not to say that the outcome of the battle was in some way predetermined. I mean only that the men actually firing guns and loading artillery were all under a force, and the sum of all such forces was too large to be influenced by a single man. He can only watch it, waving his hands and pretending to understand it.",
            "It can be hard to say what this force actually is. I like to think of it as the inevitability of the moment. Sure, the French army could have packed their bags and hastened back to their side of the Russian border before Borodino and saved a lot of lives; the atoms in the crown of the Statue of Liberty could similarly rearrange themselves 2 feet to the left. Although these things are technically possible, that is, within the army's control and obeying physical laws, they would not and could not have happened.",
            "I think that a similar thing is true of normal people and their normal lives. I think people become a certain way because their environment makes it infinitely unlikely for them to become anything drastically different. Although people have free will, it cannot be used as drastically as we like to believe. Throwing a pebble at a rolling bolder has some effect on its momentum, but it will surely not cause it to stop rolling."]}
            title={"War and Peace"}
            quotes={["Moreover, Speranski, either because he appreciated the other's capacity or because he considered it necessary to win him to his side, showed off his dispassionate calm reasonableness before Prince Andrew and flattered him with that subtle flattery which goes hand in hand with self-assurance and consists in a tacit assumption that one's companion is the only man besides oneself capable of understanding the folly of the rest of mankind and the reasonableness and profundity of one's own ideas.",
        "Does it ever happen to you to feel as if there were nothing more to come–nothing; that everything good is past? And to feel not exactly dull, but sad?",
    "Man lives consciously for himself, but is an unconscious instrument in the attainment of the historic, universal aims of humanity."]}
            date={"June 25, 2023"}
            rating={"5"}
            author="Leo Tolstoy"
            reviewed={true}
        />
    )
}

export default WarPeace;

import BookReview from "../../components/BookReview";

const Road = () => {
    return (
        <BookReview
            text={["The story was about Dean. This fact didn’t click for me until the very end of the novel which coincided with Dean’s final departure. “I think of Dean Moriarty, I even think of Old Dean Moriarty, the father we never found, I think of Dean Moriarty.” Dean embodied the spirit of the times; he was the Zeitgeist.",
                "Maybe we all have something to learn from Dean. He certainly doesn’t feel an overwhelming sense of attachment to anyone or anything. The narrator is presumably his best friend but he leaves him stranded in Mexico with dysentery when the divorce from his (second?) wife goes through. And his wives: he seems to have multiple at the same time; he is incapable of settling, incapable of deciding once and for all.",
                "And Dean certainly doesn’t worry about scratches in his watch. Dean would give his watch to a poor child in Mexico. And if the child appreciated it enough, Dean would probably extract a lifetime of watch-related enjoyment from viewing the face of the now watch-owning child. Dean exists in moments, not in years. Dean doesn’t think too much: he follows his heart and his lusts, and his whims to wherever they may take him. Fearlessly.",
                "I didn’t like the writing most of the time and I found the novel repetitive. The almost religious quaking while watching jazz musicians who’ve found It was funny. America has changed a lot. The world has changed a lot. I personally have not met anybody crazy enough (or sane enough?) to pack into a car they can barely afford and drive across a country for reasons that are at best nebulous."
            ]}
            title={"On the Road"}
            quotes={[]}
            date={"May 3, 2024"}
            rating={"3"}
            author="Jack Kerouac"
            reviewed={true}
        />
    )
}

export default Road;
